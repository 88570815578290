import React from "react"
import Slider from "react-slick"

import Logo from "../components/logo"
import Button from "../components/button"
import List from "../components/list"
import Cookie from "../components/cookie"
import CookieSimple from "../components/cookie/cookieSimple"
import PaymentGate from "../components/paymentGate"
import Wrapper from "../components/wrapper"
import MainLayout from "../components/Layout/MainLayout"
// STRIPE_SK = 'sk_live_ZoWx1mvqTYttAyNkKqlkzdkj00HRWUmIAx'

//images
import icon1 from "../images/img/ebook_icons/1.png"
import icon2 from "../images/img/ebook_icons/2.png"
import icon3 from "../images/img/ebook_icons/3.png"
import icon4 from "../images/img/ebook_icons/4.png"
import icon5 from "../images/img/ebook_icons/5.png"
import icon6 from "../images/img/ebook_icons/6.png"
import icon7 from "../images/img/ebook_icons/7.png"
import icon8 from "../images/img/ebook_icons/8.png"
import icon9 from "../images/img/ebook_icons/9.png"
import icon10 from "../images/img/ebook_icons/10.png"
import icon11 from "../images/img/ebook_icons/11.png"
import icon12 from "../images/img/ebook_icons/12.png"
import icon13 from "../images/img/ebook_icons/13.png"

// const stripePromise = loadStripe('pk_test_lPAqNKbg9YXdTmZvBA7eZqD600PK5ODEST');

let settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  slidesToScroll: 4,
  slidesToShow: 4,
  speed: 900,
  autoplaySpeed: 2000,
  easing: "swing",
  appendDots: dots => (
    <div>
      <ul style={{ margin: "0px" }} className="dotsList">
        {dots}
      </ul>
    </div>
  ),
  customPaging: () => (
    <button
      style={{
        padding: "0",
        border: "none",
        outline: "none",
        width: "8px",
        height: "8px",
      }}
      className="dotButton"
    ></button>
  ),
  responsive: [
    {
      breakpoint: 930,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 630,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
      },
    },

    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
}

class ebooksPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isPaymentGateVisible: false,
    }

    this.showPaymentGate = this.showPaymentGate.bind(this)
  }

  scrollDown = () => {
    window.scrollTo({
      left: 0,
      top: 4300,
      behavior: "smooth",
    })
  }

  showPaymentGate() {
    this.setState({ isPaymentGateVisible: true })
  }

  render() {
    const { scrollDown } = this
    return (
      <MainLayout>
        <div className="ebooksPageWrapper">
          <div className="topWrapper">
            <div className="topContent">
              <div className="logoWrapper">
                <a href="/#" title="MINDWORX" className="logo">
                  <Logo
                    style={{ width: "100%", height: "100%" }}
                    type={"white"}
                  />
                </a>
              </div>
              <h1 className="topTitles_main">
                Master persuasive communication
              </h1>
              <h2 className="topTitles_text">
                Proven psychological weapons to boost your conversion in 13
                eBooks
              </h2>
              <div className="topButtonWrapper">
                <Button size="ebooksBig" onClick={scrollDown}>
                  {" "}
                  GET IT NOW
                </Button>
              </div>
            </div>
            <div className="wave">
              <svg
                preserveAspectRatio="none"
                style={{ height: "100%", width: "100%" }}
                viewBox="0 0 500 150"
              >
                <path
                  d="M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                  style={{ stroke: "none", fill: "#fff" }}
                ></path>
              </svg>
            </div>
          </div>
          <div className="contentWrapperLargePadding">
            <h2 className="contentTitle">What’s it about?</h2>
            <p className="text">
              You will learn how to use 24 most powerful principles from
              consumer psychology and behavioral economics proven over decades
              of research and testing.
            </p>
            <p className="text">
              You will learn exactly what works, why and how to apply it to your
              ads, landing pages, emails, sales funnels or call scripts.
            </p>
          </div>
          <div className="contentWrapper">
            <div className="testimonalWrapper">
              <blockquote>
                The examples in each chapter are the most valuable thing for me.
                They are exciting, entertaining and very helpful in finding
                inspiration for advertising, copywriting or just business
                planning strategy. These eBooks are something I now keep on my
                drive to refer back to as I use this in my day to day work.
                <div className="testimonailsDescr">
                  <strong>Bonnie Michael,</strong> Vice president – Planning at
                  Ogilvy &amp; Mather, India
                </div>
              </blockquote>
            </div>
          </div>
          <div className="contentWrapperLargePadding">
            <p className="text">
              There's a ton of scientific papers full of wisdom but without any
              practical hints. YouTube gives a great overview but rarely goes
              deep enough to be of any real help.
            </p>
            <p className="text">
              So we created content we wished was out there when we were just
              starting out. It’s a sweet spot between too complex and too brief.
            </p>
            <p className="text">
              It’s hands-on, applied knowledge at your fingertips.
            </p>
            <div className="buttonWrapper">
              <Button size="ebooksSmall" onClick={scrollDown}>
                GET IT NOW
              </Button>
              <p className="underButtonText">
                After you pay, you’ll receive
                <br /> a download link to your inbox
              </p>
            </div>
          </div>
          <div className="coverWrapper">
            <h2 className="coverTitle">What’s covered?</h2>
            <div className="coverSliderWrapper">
              <Slider {...settings}>
                <div className="coverItem">
                  <img src={icon1} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon2} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon3} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon4} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon5} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon6} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon7} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon8} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon9} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon10} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon11} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon12} className="coverImg" alt="icon" />
                </div>
                <div className="coverItem">
                  <img src={icon13} className="coverImg" alt="icon" />
                </div>
              </Slider>
            </div>
          </div>
          <div className="contentWrapperLargePadding">
            <h2 className="contentTitle">Who's behind it?</h2>
            <p className="text">
              Over the last 5 year we’ve trained and advised some of the biggest
              companies out there on how to use these principles in practice.
              They include Orange, Generali, Raiffeisen Bank and many others...
            </p>
            <p className="text">
              We also stand behind the online masterclass Behavioral Economics
              &amp; Psychology in Marketing which brought together 4 of the
              world leading experts in the field as speakers.
            </p>
            <p className="text">
              But enough of us blowing our own trumpet and back to you :)
            </p>
          </div>
          <div className="contentWrapperLargePadding">
            <h2 className="contentTitle">What’s in it for you?</h2>
            <p className="text">
              You will get access to top knowledge in behavioral economics and
              consumer psychology. So that changing your communication to
              convert better will be a piece of cake.
            </p>
            <p className="text">
              Each of the 14 ebooks is about 30 pages long. That’s why it’s
              digestible and compact, yet thorough enough. It's packed with case
              studies and real-life examples.
            </p>
            <p className="text">
              So here you have it. Your way to master the science of persuasive
              communication. At your own speed and convenience.
            </p>
            <p className="text">You can decide.</p>
          </div>
          <div className="salesSection">
            <div className="buttonWrapper">
              <Button size="ebooksLong" onClick={scrollDown}>
                GET IT NOW
              </Button>
              <p className="underButtonText" style={{ paddingTop: "25px" }}>
                After you pay, you’ll receive
                <br /> a download link to your inbox
              </p>
            </div>
          </div>
          <div className="contentWrapper">
            <p className="miniTitle">
              <strong>The ebooks are right for you if </strong>
            </p>
            <List type="circle">
              <div className="circleListText">
                You run a business or e-commerce sites and are looking to raise
                your conversion rate and average order value
              </div>
              <div className="circleListText">
                You have landing pages that are failing to convert
              </div>
              <div className="circleListText">
                You would like to increase the number of people signing up to
                your newsletter
              </div>
              <div className="circleListText">
                You are keen to see your website generate more quality leads
              </div>
              <div className="circleListText">
                You want to attract more candidates through your job ads
              </div>
              <div className="circleListText">
                You want to see behavioral economics and consumer psychology
                applies to real life problems and learn how to do it yourself
              </div>
            </List>
          </div>
          <div className="contentWrapper">
            <p className="miniTitle">
              <strong>The ebooks are probably not for you if </strong>
            </p>
            <List type="circle">
              <div className="circleListText">
                You already have extensive experience in applying behavioral
                economics /work as a behavioral consultant
              </div>
              <div className="circleListText">
                You are looking for quick fix magic-bullet-style persuasion
                techniques, no matter it's impact on customer satisfaction
              </div>
              <div className="circleListText">
                You are not looking to build long term relationships with your
                clients
              </div>
              <div className="circleListText">
                You are unwilling to consider improvements that extend beyond
                changes to your communication
              </div>
            </List>
          </div>
          <div
            className="contentWrapper priceSection"
            style={{ maxWidth: "1350px", paddingTop: "50px" }}
          >
            <div className="priceItem">
              <h3 className="priceItem_h3">eBooks Bundle</h3>
              <h4 className="priceItem_h4">89 €</h4>
              <p className="priceItem_p">14 eBooks</p>
              <div className="priceButtonWrapper">
                <Button onClick={this.showPaymentGate} size="small">GET IT NOW</Button>
              </div>
            </div>
          </div>

          <Wrapper spacingBottom>
            {this.state.isPaymentGateVisible && <PaymentGate
              product="ebooks"
              hasCoupon={true}
              paymentSuccessMessage="Thank you and well done! Your eBooks are on their way."
            />}
          </Wrapper>

          <footer className="ebooksFooter">
            <div>
              <h3 className="footerTitle">Any questions?</h3>
              <p className="footerContactUs">
                We’re here to help - contact us{" "}
                <a
                  href="mailto:info@mindworxacademy.com"
                  className="footerLink"
                >
                  HERE
                </a>
              </p>
              <div className="footerLogoWrapper">
                <a href="/#" title="MINDWORX" className="logo">
                  <Logo
                    style={{ width: "100%", height: "auto" }}
                    type={"white"}
                  />
                </a>
              </div>
            </div>
          </footer>
        </div>
      </MainLayout>
    )
  }
}

export default ebooksPage
