import React from "react"

import Button from "../button"

class Cookie extends React.Component {
  state = {
    isOpenSettings: true,
  }

  handleOpenSettings = () => {
    this.setState({
      isOpenSettings: true,
    })
  }

  handleUpdateSettings = () => {
    this.setState({
      isOpenSettings: false,
    })
  }
  render() {
    const { isOpenSettings } = this.state
    const { handleOpenSettings, handleUpdateSettings } = this
    return (
      <>
        <div
          className="hiddenSettings"
          onClick={handleOpenSettings}
          role="button"
          tabindex="0"
          onKeyDown={handleOpenSettings}
        ></div>

        {isOpenSettings && (
          <>
            <div style={{ display: "block" }} className="cookieWrapper">
              <div style={{ opacity: "1" }}>
                <p className="cookie_firstParagraph">
                  We are using cookies for proper functioning, performance,
                  social media and advertising purposes. Social media and
                  advertising cookies of third parties are used to offer you
                  social media functionalities and personalized ads. To get more
                  information or amend your preferences, click{" "}
                  <a
                    className="moreInfoLink"
                    target="_blank"
                    href="/files/get/24/GDPR-MWA-ENG-COOKIE-POLICY.pdf?cache=no"
                  >
                    this link
                  </a>
                  .
                </p>

                <form action="/" method="post">
                  <div className="cookieCheckbox">
                    <input
                      id="required_cookies"
                      type="checkbox"
                      name="required_cookies"
                      value="1"
                      disabled=""
                      //   checked=""
                      className="cookieInput"
                    />
                    <label
                      htmlFor="required_cookies"
                      className="cookiesLabelDisabled"
                    >
                      Functional cookies
                      <br />
                      <small className="smallCookieStyle">
                        Functional cookies help create well usable website pages
                        by enabling basic functionality, such as page navigation
                        and access to protected website sections. Website pages
                        cannot function properly without these cookies. The
                        applicable legislation allows cookies to be stored on
                        your device if such are required for the web pages to
                        function.
                      </small>
                    </label>
                  </div>
                  <div className="cookieCheckbox">
                    <input
                      id="preferential_cookies"
                      type="checkbox"
                      name="preferential_cookies"
                      value="1"
                      //   checked=""
                      className="cookieCheckboxInput"
                    />
                    <label
                      htmlFor="preferential_cookies"
                      className="cookiesLabel"
                    >
                      Preferencial cookies <br />
                      <small className="smallCookieStyle">
                        Preferential cookies allow the website to remember
                        information which may change how the website interacts
                        or appears.
                      </small>
                    </label>
                  </div>
                  <div className="cookieCheckbox">
                    <input
                      id="analytics_cookies"
                      type="checkbox"
                      name="analytics_cookies"
                      value="1"
                      //   checked=""
                      className="cookieCheckboxInput"
                    />
                    <label htmlFor="analytics_cookies" className="cookiesLabel">
                      Statistical marketing cookies <br />
                      <small className="smallCookieStyle">
                        Analytical and statistical cookies help website owners
                        understand how to communicate with website visitors by
                        using anonymous collection and reporting of information.
                        Marketing cookies are used to track our visitors across
                        websites. Their objective is to show advertisements
                        which are relevant and engaging for individual users,
                        and thereby more valuable to publishers and third-party
                        advertisers.
                      </small>
                    </label>
                  </div>
                </form>
              </div>
              <div>
                <Button
                  size="cookie"
                  data-accept-title="Yes, I accept"
                  data-settings-title="Update settings"
                  href="#"
                  onClick={handleUpdateSettings}
                >
                  Update settings
                </Button>
              </div>
            </div>
          </>
        )}
      </>
    )
  }
}

export default Cookie
