/**
 * By page url returns currency
 * @param {string} fromPage 
 */
export const whichCurrency = fromPage => 
  fromPage === '/ebooks'
  ? 'eur'
  : fromPage === '/ebooks-us'
  ? 'usd'
  : fromPage === '/ebooks-uk'
  ? 'gbp'
  : 'eur' // default


// Returns paymentIntent object as promise
// paymentIntent has clientSecret property, which
// is required in payment confirmation
// @see confirmStripePayment()
export function stripeCreatePaymentIntent(apiUrl) {
  return new Promise((resolve, reject) => {
    fetch(apiUrl, {})
    .then(response => {
      return response.json()
    })
    .then(paymentIntent => {
      resolve(paymentIntent)
    })
    .catch(err => reject(err))
  })
}

/**
 * Creates payment intent with coupon discount
 * @param {*} apiUrl 
 * @param {*} coupon 
 * @param {*} paymentIntentId 
 */
export function stripeCreatePaymentIntentWithCoupon(apiUrl, coupon, paymentIntentId) {
  // update paymentIntent on server with new price, if coupon is corrected.
  return new Promise((resolve, reject) => {

    fetch(`${apiUrl}?coupon=${coupon}&paymentIntentId=${paymentIntentId}`) 
    .then((response) => {
      return response.json();
    })
    .then((paymentIntent) => {
      if (paymentIntent.error) {
        // handle invalid coupon ui change
        if (paymentIntent.error === "INVALID_COUPON") {
          // console.log("ERROR");
          // $('#invalid-coupon').show();
          // $('#valid-coupon').hide();
          reject(paymentIntent.error)
        }
      }
      else { // we're ok!
        // clientSecret = paymentIntent.client_secret;
        // paymentIntentId = paymentIntent.id;
        let newPrice = paymentIntent.newPrice;
        let currency = paymentIntent.currency;
        let discount = paymentIntent.discount;
        
        let formattedPrice = (newPrice / 100).toLocaleString('en-US', {
          // style: 'currency',
          // currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        });
  
        let formattedDiscount = (discount / 100).toLocaleString('en-US', {
          // style: 'currency',
          // currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        });

        resolve({ 
          paymentIntent,
          formattedPrice,
          formattedDiscount
        })
      }
    });
  })
}

/**
 * Confirms and sent all payment information to stripeInstance api.
 * This is final step in checkout process.
 * @param {*} stripeInstance 
 * @param {*} clientSecret 
 * @param {*} formData 
 */
export function confirmStripePayment(stripeInstance, clientSecret, formData) {
  return new Promise((resolve, reject) => {
    stripeInstance.confirmCardPayment(clientSecret, {
      receipt_email: formData.receipt_email,
      payment_method: {
        card: formData.card,
        billing_details: {
          email: formData.receipt_email,
          address: {
            country: formData.country_code,
            // Only if US is selected, state is filled, other countries don't have state
            state: formData.country_code === 'US' ? formData.state_code : null
          }
        }
      }
    })
    .then(result => {
      // Handle error message
      if (result.error) {
        return reject(result.error)
      }
      
      if (result.paymentIntent.status === 'succeeded') {
        resolve(result.paymentIntent)
      }
    })
  })
}
